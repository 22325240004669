import React, { useContext, useEffect, useState } from 'react';
import Botao from '../Botao';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {
  Container, CarrosselItem, CarrosselItemImagem, CarrosselItemDados,
  CarrosselItemDadosTituloProduto, CarrosselItemDadosValorProduto,
  CarrosselItemDadosAutorProduto,
  BotoesPaginacao, BotoesPaginacaoEsquerda, BotoesPaginacaoDireita, CarrosselItemDadosEstoqueProduto, Vitrine, CarroselResponsivo, CarrosselDesktop, TituloSecao
} from './styles';
import { useToast } from '../../hooks/toast';
import { CarrinhoProdutoDTO } from '../../DTOs/produtoDTO';
import { Link } from 'react-router-dom';
import { ProdutoFiltro } from '../../DTOs/ProdutoFiltro';
import cartContext from '../../Context/CartContext';
import Loading from '../Loading/Index';
import userContext from '../../Context/UserContext';
import CardProduto from '../CardProduto';
import useProdutos from '../../hooks/useProdutos';
import { formatarValores } from '../../helpers/valores';

const Carrossel: React.FC<ProdutoFiltro> = (filtro: ProdutoFiltro) => {

  const { addToast } = useToast();

  // Variáveis para gerenciamento do state
  const [paginaAtual, setPaginaAtual] = useState(0);
  // const [qtdTotalProdutos, setQtdTotalProdutos] = useState(0);
  // const [produtos, setProdutos] = useState<CarrinhoProdutoDTO[]>([]);
  const currentlyCart = useContext(cartContext);
  // const [removeLoading, setRemoveLoading] = useState(false);
  const currentlyUser = useContext(userContext);
  const [qtdItem, setQtdItem] = useState(0);
  const { Indapresentaprecocusto } = currentlyUser.user;

  useEffect(() => {

    var windowWidth = window.innerWidth;

    if (windowWidth > 1366) {
      setQtdItem(5);
    } else if (windowWidth > 923) {
      setQtdItem(4);
    } else if ((windowWidth < 541 && windowWidth > 430)) {
      setQtdItem(3);
    } else if (windowWidth < 430) {
      setQtdItem(2);
    } else {
      setQtdItem(0);
    }

  }, [window.innerWidth]);

  if (filtro.Codcliente !== 0 && filtro.Codempresacli !== 0) {
    filtro = {
      ...filtro,
      Codalmoxarifado: currentlyUser.user.Codalmoxarifado,
      Codempresa: currentlyUser.user.Codempresa,
      Codoperacao: currentlyUser.user.Codoperacao,
    }
  }

  const { produtos, setProdutos, removeLoading } = useProdutos(filtro);
  const qtdTotalProdutos = produtos.length;


  const handleAvancarItens = () => {

    // let final = Math.ceil((qtdTotalProdutos / 2));
    let final = qtdTotalProdutos - qtdItem;

    if (paginaAtual <= final)
      setPaginaAtual((page) => page + 1);

  };

  const handleVoltarItens = () => {

    if (paginaAtual > 0) {
      setPaginaAtual((page) => page - 1);
    }
  };

  function handleAlterarQuantidade(id: number, qtd: number) {

    const index = produtos.findIndex(produtoCarrinho => produtoCarrinho.CodProduto === id);

    // Se achou produto
    if (index > -1) {
      const produto: CarrinhoProdutoDTO = produtos[index];
      // Armazena o objeto na variável
      produto.Qtd = qtd;


      setProdutos(
        [...produtos.slice(0, index),
          produto,
        ...produtos.slice(index + 1)]
      );

    }

    currentlyCart.alteraQuantidade(id, qtd);

  };

  function handleAdicionarCarrinho(produto: CarrinhoProdutoDTO) {

    const index = produtos.findIndex(produtoCarrinho => produtoCarrinho.CodProduto === produto.CodProduto);

    // Se achou produto
    if (index > -1) {
      // Armazena o objeto na variável
      produto.Qtd = produtos[index].Qtd
      produto.isDeleted = false;

    } else {
      produto.NroItem = 0;
    }
    currentlyCart.buscaCarrinho(currentlyCart.cart.CodCliente ? currentlyCart.cart.CodCliente : 0);
    currentlyCart.adicionaCarrinho(produto);

    addToast({
      type: 'success',
      title: 'Produto adicionado ao carrinho'
    });

  };

  return (

    <>
      {produtos.length <= 0 ? null : <>
        <TituloSecao>
          {filtro.titulo}
        </TituloSecao>
        <CarrosselDesktop>

          <Container>

            <BotoesPaginacao>

              <BotoesPaginacaoEsquerda>
                <Botao onClick={() => handleVoltarItens()} containerStyle={{
                  backgroundColor: "#fff", borderColor: "var(--botoesPaginacao-bg-color)",
                  color: "var(--botoesPaginacao-bg-color)", borderWidth: 1, borderStyle: "solid",
                }}>
                  <FontAwesomeIcon icon={faChevronLeft} size={"2x"} />
                </Botao>
              </BotoesPaginacaoEsquerda>

              <CardProduto Produtos={produtos.slice(paginaAtual, paginaAtual + qtdItem)} SetProdutos={setProdutos} />

              <BotoesPaginacaoDireita>
                <Botao onClick={() => handleAvancarItens()} containerStyle={{
                  backgroundColor: "#fff", borderColor: "var(--botoesPaginacao-bg-color)",
                  color: "var(--botoesPaginacao-bg-color)", borderWidth: 1, borderStyle: "solid",
                }}>
                  <FontAwesomeIcon icon={faChevronRight} size={"2x"} />
                </Botao>
              </BotoesPaginacaoDireita>

            </BotoesPaginacao>

          </Container >

        </CarrosselDesktop>

        <CarroselResponsivo>
          <div className='botoesPaginacao'>

            <div className='botaoPagEsquerda'>
              <BotoesPaginacaoEsquerda>
                <Botao onClick={() => handleVoltarItens()} containerStyle={{
                  backgroundColor: "#fff", borderColor: "var(--botoesPaginacao-bg-color)",
                  color: "var(--botoesPaginacao-bg-color)", borderWidth: 1, borderStyle: "solid",
                }}>
                  <FontAwesomeIcon icon={faChevronLeft} size={"2x"} />
                </Botao>
              </BotoesPaginacaoEsquerda>
            </div>

            <BotoesPaginacaoDireita>
              <Botao onClick={() => handleAvancarItens()} containerStyle={{
                backgroundColor: "#fff", borderColor: "var(--botoesPaginacao-bg-color)",
                color: "var(--botoesPaginacao-bg-color)", borderWidth: 1, borderStyle: "solid",
              }}>
                <FontAwesomeIcon icon={faChevronRight} size={"2x"} />
              </Botao>
            </BotoesPaginacaoDireita>
          </div>

          <Container>

            <BotoesPaginacao>
              {
                !removeLoading && <Loading />
              }
              {produtos.length === 0 && removeLoading && (
                // Se não vier nada da API, exibe uma mensagem de aviso
                <p>Nenhum produto cadastrado.</p>
              )}

              {produtos.map((produto) => (
                // Faz um loop, mostrando os carrinhos que vieram da resposta da API
                <CarrosselItem key={produto.CodProduto}>

                  <CarrosselItemImagem>
                    <a href={`/produtos/${produto.CodProduto}/detalhes`}>{<img src={produto.Src} alt={produto.Titulo} />}</a>
                  </CarrosselItemImagem>

                  <CarrosselItemDados>

                    <CarrosselItemDadosTituloProduto>
                      <a href={`/produtos/${produto.CodProduto}/detalhes`}>{produto.Titulo.length > 30 ? produto.Titulo.substring(0, 30) + "..." : produto.Titulo}</a>
                    </CarrosselItemDadosTituloProduto>

                    <CarrosselItemDadosValorProduto>
                      {/* <b>{produto.VlrFormatado}</b>
                      &nbsp;
                      {produto.Desconto > 0 ? <b>{produto.Desconto}%</b> : null} */}
                      {Indapresentaprecocusto && produto.Desconto != null && produto.Desconto > 0 ? (
                        <div className="dePorValores">
                          <div className="dePor">
                            <p className="valor">
                              De {produto.VlrFormatado}
                            </p>

                          </div>
                          <p className="valorFinal">
                            Por {
                              (produto.VlrTotalFormatado = formatarValores(
                                produto.Vlr -
                                produto.Vlr * (produto.Desconto / 100)
                              ))
                            }
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p className="valorFinal">
                            <b>{produto.VlrFormatado}</b>
                          </p>
                        </div>
                      )}
                    </CarrosselItemDadosValorProduto>

                    <CarrosselItemDadosAutorProduto>
                      <Link to={`/editora/${produto.CodEditora}/${encodeURIComponent(produto.NomEditora)}`}>{produto.NomEditora.length > 25 ? produto.NomEditora.substring(0, 25) + "..." : produto.NomEditora}</Link>
                    </CarrosselItemDadosAutorProduto>
                  </CarrosselItemDados>

                  <CarrosselItemDadosEstoqueProduto>

                    {/* {produto.Qtdestoque > 0 ? <p>Em estoque: <b> {produto.Qtdestoque}</b></p>
                     : produto.Qtdestoque === -1 ? <p className='foraCatalogo'>{produto.Dscsituacaoestoque}</p> 
                      : "Sem estoque"} */}
                    {/* {produto.Qtdestoque > 0 ? <p>{produto.Dscsituacaoestoque}: <b> {produto.Qtdestoque}</b></p>
                                : produto.Qtdestoque == -1 && produto.QuantidadeEmEstoque != null && produto.QuantidadeEmEstoque <= 0 ? <p>{produto.Dscsituacaoestoque}</p>
                                    : produto.Qtdestoque == -1 && produto.QuantidadeEmEstoque > 0 ? <p>{produto.Dscsituacaoestoque}: <b> {produto.QuantidadeEmEstoque}</b></p>
                                        : produto.Qtdestoque == null && produto.Dscsituacaoestoque == null &&  produto.QuantidadeEmEstoque == null ? <p> ESTOQUE: <b>0 </b> </p>:
                                          produto.Dscsituacaoestoque != null ? produto.Dscsituacaoestoque : <p> ESTOQUE: <b>0 </b> </p> }  */}
                    {produto.Qtdestoque > 0 ? (
                      <p>{produto.Dscsituacaoestoque}: <b>{produto.Qtdestoque}</b></p>
                    ) : produto.Qtdestoque == -1 && produto.QuantidadeEmEstoque != null ? (
                      produto.QuantidadeEmEstoque <= 0 ? (
                        <p>{produto.Dscsituacaoestoque}</p>
                      ) : (
                        <p>{produto.Dscsituacaoestoque}: <b>{produto.QuantidadeEmEstoque}</b></p>
                      )
                    ) : produto.Qtdestoque == 0 && produto.Dscsituacaoestoque == null ? (
                      <p>ESTOQUE: <b>0</b></p>
                    ) : produto.Dscsituacaoestoque != null ?
                    produto.Qtdestoque >= 0 ? (
                      <p>{produto.Dscsituacaoestoque}: <b>{produto.Qtdestoque}</b></p>
                    ) : (
                      <p>{produto.Dscsituacaoestoque}</p>
                    ) : null}
                  </CarrosselItemDadosEstoqueProduto>

                  <Vitrine>
                    <div className="aumentarEcomprar">
                      <div className="inputNumeros">
                        <input
                          type="number"
                          className="contadorLivros"
                          defaultValue="1"
                          min="1"
                          max="100"
                          value={
                            currentlyCart.cart.Produtos?.find((p) => {
                              return p.CodProduto === produto.CodProduto;
                            })?.Qtd
                          }
                          onChange={(e) =>
                            handleAlterarQuantidade(
                              produto.CodProduto,
                              Number(e.target.value)
                            )
                          }
                          name="qtd"
                        />
                      </div>
                      <div className="buttonAdc">

                        {produto.Qtdestoque > 0 ?
                          <button onClick={() => handleAdicionarCarrinho(produto)} className="adcCarrinho">Adicionar ao Carrinho</button>
                          : produto.Qtdestoque === -1 && produto.QuantidadeEmEstoque <= 0 ? <button className="adcCarrinhoDisable">Adicionar ao Carrinho</button>
                            : produto.Qtdestoque === -1 && produto.QuantidadeEmEstoque > 0 ? <button onClick={() => handleAdicionarCarrinho(produto)} className="adcCarrinho">Adicionar ao Carrinho</button>
                              : <button onClick={() => handleAdicionarCarrinho(produto)} className="adcCarrinho">Adicionar ao Carrinho</button>
                        }
                      </div>
                    </div>
                  </Vitrine>

                </CarrosselItem>
              ))}

            </BotoesPaginacao>

          </Container>

        </CarroselResponsivo>
      </>}
    </>)
}




export default Carrossel;
